<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          title="Konto bearbeiten"
        >
          <v-card-title>Konto bearbeiten</v-card-title>

          <v-card-text>

            <div class="text-center" v-if="loading">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-form
              v-else
              ref="form"
              @submit.prevent="handleFormSubmit"
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email"
                    :prepend-inner-icon="icons.mdiEmailOutline"
                    label="E-Mail"
                    type="email"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="E-Mail"
                    :error="(errorMessages.email != undefined)"
                    :error-messages="errorMessages.email"
                    :rules="[validators.required, validators.emailValidator]"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="passwort"
                    :prepend-inner-icon="icons.mdiLockOutline"
                    label="Passwort"
                    outlined
                    dense
                    :type="isPasswordVisible ? 'text' : 'password'"
                    placeholder="Passwort"
                    hide-details="auto"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :error="errorMessages.passwort != undefined"
                    :error-messages="errorMessages.passwort"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="passwort_w"
                    :prepend-inner-icon="icons.mdiLockOutline"
                    label="Passwort-Wiederholung"
                    outlined
                    dense
                    :type="isPasswordVisible ? 'text' : 'password'"
                    placeholder="Passwort-Wiederholung"
                    hide-details="auto"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :error="errorMessages.passwort_w != undefined"
                    :error-messages="errorMessages.passwort_w"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>



              </v-row>

              <v-row>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="vorname"
                    label="Vorname"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Vorname"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Name"
                  ></v-text-field>
                </v-col>


                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="strasse"
                    label="Strasse"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Strasse"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="hausNr"
                    label="Nr."
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Nr."
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="plz"
                    label="PLZ"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="PLZ"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="ort"
                    label="Ort"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Ort"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="telefon"
                    label="Telefon"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Telefon"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="6">
                  <v-avatar
                    :color="avatar ? '' : 'primary'"
                    :class="avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="120"
                    rounded
                    style="cursor:pointer;"
                    class="mb-4"
                    @click="$refs.file.click()"
                  >
                    <v-img
                      class="uploadImage"
                      v-if="image"
                      :src="image"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-semibold text"
                    >Foto hochladen</span>
                  </v-avatar>

                  <input ref="file" v-on:change="onFileSelected" type="file" id="foto">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn color="primary" type="submit">
                    Speichern
                  </v-btn>
                  <v-btn
                    type="reset"
                    outlined
                    class="mx-2"
                  >
                    Zurücksetzen
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {onUnmounted, onMounted, ref, getCurrentInstance} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import {
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import userStoreModule from '@/views/account/userStoreModule'
import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";

export default {
  components: {

  },
  setup() {
    const loading = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const form = ref(null)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const passwort = ref('')
    const passwort_w = ref('')
    const vorname = ref('')
    const name = ref('')
    const strasse = ref('')
    const hausNr = ref('')
    const plz = ref('')
    const ort = ref('')
    const telefon = ref('')
    const foto = ref(null)
    const avatar = ref('')
    const image = ref('')

    const userData = ref({})
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)
    const errorMessages = ref([])

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(()=>{
      axios.post('/api/verwaltung/benutzerdaten/', {
        aktion: 'editUserData'
      })
        .then((response) => {
          if (response.data.valid === true) {
            email.value = response.data.user.email;
            passwort.value = response.data.user.passwort;
            vorname.value = response.data.user.vorname;
            name.value = response.data.user.name;
            strasse.value = response.data.user.strasse;
            hausNr.value = response.data.user.hausNr;
            plz.value = response.data.user.plz;
            ort.value = response.data.user.ort;
            telefon.value = response.data.user.telefon;
            foto.value = response.data.user.foto;

            image.value = foto.value;
          } else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch((error) => {
          console.log(error)
          errorMessages.value = error
          loading.value = true;
        })
    })

    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion','editUserDataCheck');
      if(foto.value.name !== undefined){
        fd.append('userData[foto]',foto.value,foto.value.name);
      }
      fd.append('userData[email]',email.value);
      fd.append('userData[passwort]',passwort.value);
      fd.append('userData[passwort_w]',passwort_w.value);
      fd.append('userData[vorname]',vorname.value);
      fd.append('userData[name]',name.value);
      fd.append('userData[strasse]',strasse.value);
      fd.append('userData[hausNr]',hausNr.value);
      fd.append('userData[plz]',plz.value);
      fd.append('userData[ort]',ort.value);
      fd.append('userData[telefon]',telefon.value);
      axios
        .post('/api/verwaltung/benutzerdaten/',fd)
        .then(response => {
          if(response.data.valid === true){
            passwort.value = "";
            passwort_w.value = "";
            vm.$toastr.s(response.data.message.text, response.data.message.title);
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          errorMessages.value = error
          loading.value = false;
        })
    }

    const onFileSelected = (event) =>{
      foto.value = event.target.files[0];
      if(foto.value) {
        const reader = new FileReader();
        reader.onload = function(evt) {
          image.value = evt.target.result;
        };
        reader.readAsDataURL(foto.value);
      }
    }

    return {
      handleFormSubmit,
      loading,
      userTab,
      form,
      errorMessages,
      email,
      passwort,
      passwort_w,
      vorname,
      name,
      strasse,
      hausNr,
      plz,
      ort,
      telefon,
      foto,
      image,
      isPasswordVisible,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      userData,
      avatar,
      onFileSelected,
      icons: {
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

#foto{
  display:none
}
.uploadImage{
  cursor: pointer;
}

</style>
